<script>
import Swal from "sweetalert2";
import Advertising from "@/services/Advertising";

export default {
  props: {
    media: Array,
  },
  data() {
    return {
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 6,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "id",
          label: "Media ID",
          sortable: true,
        },
        {
          key: "source_url",
          label: "Image",
          sortable: true,
        },
        {
          key: "type",
          label: "Media Type",
          sortable: true,
        },
        {
          key: "text",
          label: "Media Text",
          sortable: true,
        },
        {
          key: "width",
          label: "Media Width",
          sortable: true,
        },
        {
          key: "height",
          label: "Media Height",
          sortable: true,
        },
        {
          key: "active",
          label: "Active",
          sortable: true,
        },
        {
          key: "timestamp",
          label: "Timestamp",
          sortable: true,
        },
        "action",
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.media != null ? this.media.length : 0;
    },
  },
  mounted() {
    this.totalRows = 0;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    removeMedia(sponsor_id, media_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Remove From Media !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.value) {
          Advertising.removeSponsoredMedia(sponsor_id, media_id)
            .then((response) => {
              const res = response.data.data ? response.data.data : false;
              const error = response.data.error ? response.data.error : "";
              if (res && error == "") {
                this.$emit("onRefresh");
                this.successmsg("Media Successfully Removed");
              } else {
                this.failedmsg("Media Removal Failed");
              }
            })
            .catch((error) => {
              this.failedmsg("Fail!", error);
            });
        }
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive mb-0">
        <b-table
          :items="media"
          :busy="isBusy"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
          empty-text="No Data Found"
        >
          <template v-slot:cell(source_url)="media">
            <div class="flex-shrink-0 ">
              <a
                :title="media.item.text"
                :href="media.item.source_url"
                target="_blank"
                ><img
                  class=" img-fluid img-thumbnail table-images"
                  :src="media.item.source_url"
              /></a>
            </div>
          </template>
          <template v-slot:cell(action)="media">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a
                  href="javascript:void(0);"
                  class="px-2 text-danger"
                  title="Remove Media"
                  @click="removeMedia(media.item.sponsor_id, media.item.id)"
                >
                  <i class="uil uil-trash-alt font-size-18"></i>
                </a>
              </li>
            </ul>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="col-12">
      <div class="dataTables_paginate paging_simple_numbers float-end">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </ul>
      </div>
    </div>
  </div>
</template>
